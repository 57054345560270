import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CustomToolbar from '../../Share/Table/CustomToolbar';
import ConfirmDialog from '../../Share/ConfirmDialog';
import i18next from '../../../language/i18n';
import DateProvider from '../../../share/dateProvider';
import { getAllLegalService_Done } from "../../../actions/legalService_WarningAction";
import { getSelectBoxLegalService_Courts } from "../../../actions/legalService_CourtAction";
import { getSelectBoxLegalService_ExecutionTypes } from "../../../actions/legalService_ExecutionTypeAction";


class TableLegalService_Done extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, IsOpenFormDialog: false, item: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxLegalService_Courts();
    this.props.getSelectBoxLegalService_ExecutionTypes();
    this.props.getAllLegalService_Done();
  }
  courtName = (props) => {
    return this.props.legalService_Courts.find(el => el.value === props.row.courtForEnforcementID)?.text;
  }
  executionTypeName = (props) => {
    return this.props.legalService_ExecutionTypes.find(el => el.value === props.row.executionTypeID)?.text;
  }
  columns = [ 
    {
      field: 'loanNumber',
      headerName: i18next.t('LoanNumber'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link href={"#/Loan/" + params.row.loanID}
          >
            {params.row.loanNumber ?? i18next.t('Loan')}
          </Link>
        </strong>
      ),
    },
    // { field: 'referenceNumber', headerName: i18next.t('ReferenceNumber2'), minWidth: 150 },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 2 },
    { field: 'latePaymentDays', headerName: i18next.t('LatePaymentDays'), minWidth: 50 },
    { field: 'amountOfDebt', headerName: i18next.t('Amount'), minWidth: 50 },
    { field: 'executionDate', headerName: i18next.t('ExecutionDate'), minWidth: 50, flex: 1, type:'date', valueGetter: DateProvider.GetDate },
    {
      field: 'courtForEnforcementID',
      headerName: i18next.t('CourtForEnforcement'),
      width: 160,
      valueGetter: this.courtName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.courtName(cellParams1).localeCompare(this.courtName(cellParams2))
    },
    {
      field: 'executionTypeID',
      headerName: i18next.t('ExecutionType'),
      width: 160,
      valueGetter: this.executionTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.executionTypeName(cellParams1).localeCompare(this.executionTypeName(cellParams2))
    },  
  ];
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.legalService_Dones}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    legalService_Dones: Object.values(state.legalService_Dones),
    legalService_Courts: Object.values(state.legalService_CourtsSelectBox),
    legalService_ExecutionTypes: Object.values(state.legalService_ExecutionTypesSelectBox)
  };
}

export default connect(mapStateToProps, {
  getAllLegalService_Done,
  getSelectBoxLegalService_Courts,
  getSelectBoxLegalService_ExecutionTypes
})(TableLegalService_Done);