import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLegalService_Warning } from '../../../actions/legalService_WarningAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditDatePickerField, EditCheckbox } from '../../Share/EditComponents';
import SubmitButton from '../../Share/Button/SubmitButton';
import CancelButton from '../../Share/Button/CancelButton';

class FormLegalService_Execution extends React.Component {
    handleClose = () => {
        //this.props.closeDialogLegalService_Warning();
        this.props.onClose();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='executionTypeID'
                                    component={this.props.EditSelectField}
                                    label='ExecutionType'
                                    dataSource={this.props.legalService_ExecutionTypes}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='courtForEnforcementID'
                                    component={this.props.EditSelectField}
                                    label='CourtForEnforcement'
                                    dataSource={this.props.legalService_CourtsSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='reportText'
                                    component={this.props.EditTextField}
                                    label='ReportText'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if (!formValues.referenceNumber) {
        errors.referenceNumber = 'Polje je obavezno!';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        legalService_ExecutionTypes: Object.values(state.legalService_ExecutionTypesSelectBox),
        legalService_CourtsSelectBox: Object.values(state.legalService_CourtsSelectBox)
    };
}

const formWrapper = reduxForm({
    form: 'FormLegalService_Execution',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormLegalService_Execution);

export default connect(mapStateToProps, { closeDialogLegalService_Warning, EditSelectField, EditTextField, EditDecimalNumberField, EditDatePickerField, EditCheckbox })(formWrapper);