import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLegalService_Warning } from '../../actions/legalService_WarningAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditDatePickerField, EditCheckbox2 } from '../Share/EditComponents';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';

class FormReferenceNumberLegalService_Warning extends React.Component {
    constructor(props) {
        super(props);
        this.state = { useTodaysDate: true }
    }
    handleClose = () => {
        //this.props.closeDialogLegalService_Warning();
        this.props.onClose();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            {/* <div>
                                <Field
                                    name='referenceNumber'
                                    component={this.props.EditTextField}
                                    label='ReferenceNumber2'
                                />
                            </div> */}
                            <div>
                                <EditCheckbox2 name='todaysDate'
                                    value={this.state.useTodaysDate}
                                    label='TodaysDate'
                                    onChange={(e) => { this.setState({ useTodaysDate: !this.state.useTodaysDate }) }}
                                />
                            </div>
                            {
                                this.state.useTodaysDate == true ? null :
                                    <>
                                        <div>
                                            <Field
                                                name='dateOfWarning'
                                                component={this.props.EditDatePickerField}
                                                label='DateOfWarning'
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name='debtAmount'
                                                component={this.props.EditDecimalNumberField}
                                                label='WarningDebtAmount'
                                            />
                                        </div>
                                    </>
                            }
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    /* if(!formValues.referenceNumber){
         errors.referenceNumber = 'Polje je obavezno!';
     }*/
    return errors;
}

const formWrapper = reduxForm({
    form: 'formReferenceNumberLegalService_Warning',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormReferenceNumberLegalService_Warning);

export default connect(null, {
    closeDialogLegalService_Warning,
    EditSelectField,
    EditTextField,
    EditDecimalNumberField,
    EditDatePickerField,
    EditCheckbox2
})(formWrapper);