import React from "react";
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import i18next from '../../language/i18n';
import history from "../../share/history";
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';


class UserManu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      anchorEl: null
    }
    //this.anchorRef = React.createRef();
  }

  onClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  onClose = () => {
    this.setState({ anchorEl: null });
  };
  changePassword = () => {
    this.onClose();
    history.push("/ChangePassword");
  };
  logout = () => {
    this.setState({ anchorEl: null });
    localStorage.clear();
    window.location.reload(true);
  };

  render() {
    return (
      <>
        <Menu sx={{ width: 520, maxWidth: '100%', marginTop:'40px' }}
          id="menu-appbar"
          anchorEl={this.state.anchorEl?.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(this.state.anchorEl)}
          onClose={this.onClose}
        >
          <MenuItem onClick={this.changePassword}>
            <ListItemIcon>
              <PasswordIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{i18next.t('ChangePassword')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={this.logout}>
            <ListItemIcon>
              <LogoutIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{i18next.t('Logout')}</ListItemText>
          </MenuItem>
        </Menu>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.onClick}
          color="inherit"
        >         
          <AccountCircle/>
        </IconButton>
      </>
    );
  };
}



export default connect()(UserManu);