import * as React from 'react';
import { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import ReportIcon from '@mui/icons-material/Report';
import SmsIcon from '@mui/icons-material/Sms';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import i18next from '../language/i18n';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GavelIcon from '@mui/icons-material/Gavel';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';

import { Link } from "react-router-dom";
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import styles from '../assets/jss/material-dashboard-react/components/mainStyle';
import { connect } from 'react-redux';
import { changeTitle } from '../actions/titleAction';
import Tooltip from '@mui/material/Tooltip';

class MyMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    }
  }
  admin = 'Admin';
  clientAdmin = 'ClientAdmin';
  companyAdmin = 'CompanyAdmin';
  broker = 'Broker';
  seller = 'Seller';
  legalService = 'LegalService';
  accounting = 'Accounting';
  manuData = [
    {
      link: "/",
      icon: <DashboardIcon />,
      text: i18next.t('Dashboard'),
      roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller, this.legalService, this.accounting],
      items: null
    },
    {
      link: null,
      icon: <AccountBalanceIcon />,
      text: i18next.t('Accounting'),
      roles: [this.admin, this.clientAdmin, this.accounting],
      items: [
        // {
        //   link: "null",
        //   icon: null,
        //   text: i18next.t('Cashier'),
        //   roles: [this.admin, this.clientAdmin, this.accounting],
        //   items: [
        //     {
        //       link: "/CashBalance",
        //       icon: <TrendingUpIcon />,
        //       text: i18next.t('CashBalance'),
        //       roles: [this.admin, this.clientAdmin, this.accounting],
        //       items: null
        //     },
        //     {
        //       link: "/KUF",
        //       icon: null,
        //       text: i18next.t('KUF'),
        //       roles: [this.admin, this.clientAdmin, this.accounting],
        //       items: null
        //     }
        //   ]
        // },
        {
          link: "/BankImport",
          icon: null,
          text: i18next.t('ImportCredits'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },
        {
          link: "/CashBalance",
          icon: <TrendingUpIcon />,
          text: i18next.t('CashBalance'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },
        {
          link: "/PostingAccount",
          icon: null,
          text: i18next.t('PostingAccount'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },
        {
          link: "/OrderInImbalance",
          icon: null,
          text: i18next.t('OrderInImbalance'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },        
        {
          link: "/KUF",
          icon: null,
          text: i18next.t('KUF'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },        
        {
          link: "/Order",
          icon: null,
          text: i18next.t('OrderList'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        },        
        {
          link: "/OrderType",
          icon: null,
          text: i18next.t('OrderType'),
          roles: [this.admin, this.clientAdmin, this.accounting],
          items: null
        }]        
    },
    {
      link: null,
      icon: <AccountBalanceWalletIcon />,
      text: i18next.t('Loan'),
      roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller, this.legalService, this.accounting],
      items: [
        {
          link: "/Loan",
          icon: <AssignmentIcon />,
          text: i18next.t('AddLoan'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller],
          items: null
        },
        {
          link: "/Draft",
          icon: <AssignmentIcon />,
          text: i18next.t('Draft'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller],
          items: null
        },
        {
          link: "/CreditBoard",
          icon: <AssignmentIcon />,
          text: i18next.t('CreditBoard'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller],
          items: null
        },
        {
          link: "/ApprovedLoan",
          icon: <AssignmentIcon />,
          text: i18next.t('ApprovedLoan'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller],
          items: null
        },
        {
          link: "/LoanForRealization",
          icon: <AssignmentIcon />,
          text: i18next.t('LoanRealization'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
          items: null
        },
        {
          link: "/LoanHalcom",
          icon: <AssignmentIcon />,
          text: i18next.t('LoanHalcom'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting],
          items: null
        },
        {
          link: "/AllLoans",
          icon: <AssignmentIcon />,
          text: i18next.t('AllLoans'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.broker, this.seller],
          items: null
        },
        {
          link: "/LoanMaintenance",
          icon: <AssignmentIcon />,
          text: i18next.t('LoanMaintenance'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting],
          items: null
        },
        {
          link: "/CRKArchive",
          icon: <AssignmentIcon />,
          text: i18next.t('CRKArchive'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting],
          items: null
        },
        {
          link: null,
          icon: <ReportIcon />,
          text: i18next.t('Warnings'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin],
          items: [
            {
              link: "/PayWarning",
              icon: <ReportIcon />,
              text: i18next.t('PaymentNote'),
              roles: [this.admin, this.clientAdmin, this.companyAdmin],
              items: null
            },
            {
              link: "/SMSWarning",
              icon: <SmsIcon />,
              text: i18next.t('SMS'),
              roles: [this.admin, this.clientAdmin, this.companyAdmin],
              items: null
            },
            {
              link: "/DebtCollectionCostDetails",
              icon: <ReportIcon />,
              text: i18next.t('DebtCollectionCost'),
              roles: [this.admin, this.clientAdmin, this.companyAdmin],
              items: null
            }
          ]
        }
      ]//
    },
    {
      link: null,
      icon: <GroupIcon />,
      text: i18next.t('UsersInfo'),
      roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
      items: [
        {
          link: "/Partner",
          icon: <PersonIcon />,
          text: i18next.t('Partner'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
          items: null
        },
        {
          link: "/PartnerDetails",
          icon: <PersonIcon />,
          text: i18next.t('PartnerDetails'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
          items: null
        },
        {
          link: "/RelatedPerson",
          icon: <GroupsIcon />,
          text: i18next.t('RelatedPerson'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin],
          items: null
        },
        {
          link: "/User",
          icon: <PersonIcon />,
          text: i18next.t('User'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin],
          items: null
        },
        {
          link: "/Broker",
          icon: <PersonIcon />,
          text: i18next.t('Broker'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin],
          items: null
        },
        {
          link: "/BoardMember",
          icon: <PersonIcon />,
          text: i18next.t('BoardMember'),
          roles: [this.admin, this.clientAdmin],
          items: null
        }]
    },
    {
      link: null,
      icon: <ReportIcon />,
      text: i18next.t('LegalService'),
      roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
      items: [
        {
          link: "/LegalService_AllDebts",
          icon: <ReportIcon />,
          text: i18next.t('AllDebts'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_Warning",
          icon: <ReportIcon />,
          text: i18next.t('Warning'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_Redemption",
          icon: <ReportIcon />,
          text: i18next.t('RedemptionTermination'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_Execution",
          icon: <ReportIcon />,
          text: i18next.t('Execution'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_Done",
          icon: <DoneIcon />,
          text: i18next.t('Done'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_ExecutionType",
          icon: null,
          text: i18next.t('ExecutionType'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        },
        {
          link: "/LegalService_Court",
          icon: <GavelIcon />,
          text: i18next.t('Court'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.legalService],
          items: null
        }]
    },
    {
      link: null,
      icon: <SettingsIcon />,
      text: i18next.t('Admin'),
      roles: [this.admin, this.clientAdmin],
      items: [
        {
          link: "/Company",
          icon: <BusinessIcon />,
          text: i18next.t('Company'),
          roles: [this.admin],
          items: null
        },
        {
          link: "/CompanyDetails",
          icon: null,
          text: i18next.t('CompanyDetails'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/Branch",
          icon: null,
          text: i18next.t('Branch'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/Cashier",
          icon: null,
          text: i18next.t('Cashier'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/Region",
          icon: <LocationOnIcon />,
          text: i18next.t('Region'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/City",
          icon: <LocationCityIcon />,
          text: i18next.t('City'),
          roles: [this.admin],
          items: null
        },
        {
          link: "/Bank",
          icon: <AccountBalanceIcon />,
          text: i18next.t('Bank'),
          roles: [this.admin],
          items: null
        },
        {
          link: "/OpinionOfTheReferent",
          icon: null,
          text: i18next.t('OpinionOfTheReferent'),
          roles: [this.admin],
          items: null
        },
        {
          link: "/LoanApprovalSettings",
          icon: null,
          text: i18next.t('LoanApprovalSettings'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/EarlyRepaymentSettings",
          icon: null,
          text: i18next.t('EarlyRepaymentSettings'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },        
        {
          link: "/PenaltyInterestSettings",
          icon: null,
          text: i18next.t('PenaltyInterestSettings'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/FileUploadType",
          icon: null,
          text: i18next.t('FileUploadType'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },
        {
          link: "/LoanType",
          icon: null,
          text: i18next.t('LoanType'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },  
        {
          link: "/SourceOfFinancing",
          icon: null,
          text: i18next.t('SourceOfFinancing'),
          roles: [this.admin, this.clientAdmin],
          items: null
        },       
        {
          link: "/YearLock",
          icon: <LockIcon/>,
          text: i18next.t('YearLock'),
          roles: [this.admin, this.clientAdmin],
          items: null
        }/*,
        {
          link: "/LifeInsurance",
          icon: null,
          text: i18next.t('LifeInsurance'),
          roles: [this.admin, this.clientAdmin],
          items: null
        }*/
      ]
    },
    {
      link: null,
      icon: <AssessmentIcon />,
      text: i18next.t('Reports'),
      roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
      items: [
        {
          link: "/GeneralLedger",
          icon: <PictureAsPdfIcon />,
          text: i18next.t('GeneralLedger'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting],
          items: null
        },
        {
          link: "/MatureAnnuities",
          icon: <PictureAsPdfIcon />,
          text: i18next.t('MatureAnnuities'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin,  this.broker, this.seller],
          items: null
        },
        {
          link: "/FinancialCard",
          icon: <PictureAsPdfIcon />,
          text: i18next.t('FinancialCard'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin],
          items: null
        },
        {
          link: "/Portfolio",
          icon: <PictureAsPdfIcon />,
          text: i18next.t('Portfolio'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting, this.broker, this.seller],
          items: null
        },
        {
          link: "/AgencyReports",
          icon: <PictureAsPdfIcon />,
          text: i18next.t('AgencyReports'),
          roles: [this.admin, this.clientAdmin, this.companyAdmin, this.accounting],
          items: null
        }]
    }
  ]


  getItem = (item) => {
    const { classes } = this.props;
    const fontColor = this.props.isDark ? classes.whiteFont : classes.blackFont;
    const itemText = `${classes.itemText} ${fontColor}`;

    if (item.items === null) {
      return (<Link to={item.link} key={item.link} onClick={() => this.props.changeTitle(item.text)}>
        <ListItem className={classes.itemLink}>
          <Tooltip title={item.text}>
            <ListItemIcon className={classes.itemIcon}>
              {item.icon ?? item.text[0]}
            </ListItemIcon>
          </Tooltip>
          {this.props.isOpen ? <ListItemText primary={item.text} className={itemText} /> : null}
        </ListItem>
      </Link>);
    }
    else {
      let name = "is" + item.text + "Open";
      return (
        <Fragment key={item.text}>
          <ListItem className={classes.itemLink} onClick={() => this.setState({ [name]: !this.state[name] })}>
            <ListItemIcon className={classes.itemIcon}>
              {item.icon ?? <AccountBalanceIcon />}
            </ListItemIcon>
            {this.props.isOpen ? <ListItemText primary={item.text} className={itemText} /> : null}
            {this.state[name] ? <ExpandLess className={classes.expand} /> : <ExpandMore className={classes.expand} />}
          </ListItem>
          <Collapse className={classes.collapseItem} in={this.state[name]} timeout="auto" unmountOnExit>
            <List className={classes.list}>
              {this.getData(item.items)}
            </List>
          </Collapse>
        </Fragment>);
    }
  }
  getData = (data) => {
    return data.filter(el => this.hasPermission(el.roles)).map(el => this.getItem(el));
  }
  hasPermission = (roles) => {
    for (let i = 0; i < roles?.length; i++) {
      if (this.props.authUser.roles?.indexOf(roles[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  render() {
    return (<>{this.getData(this.manuData)}</>);
    /*return(
      <>
        <Link to="/Entity">
          <ListItem button  className={classes.itemLink}>
            <ListItemIcon className={classes.itemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" className={classes.itemText} />
          </ListItem>
        </Link>
        <ListItem button  className={classes.itemLink} onClick={() => this.setState({isOpen:!this.state.isOpen})}>
            <ListItemIcon className={classes.itemIcon}>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Test" className={classes.itemText}/>
            {this.state.isOpen ? <ExpandLess className={classes.expand}/> : <ExpandMore className={classes.expand}/>}
        </ListItem>
        <Collapse className={classes.collapseItem} in={this.state.isOpen} timeout="auto" unmountOnExit>
          <List className={classes.list} style={{color:'red'}}>
            <Link to="/">
            <ListItem button className={classes.itemLink} style={{color:'green'}}>
              <ListItemIcon className={classes.itemIcon}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Test 00" className={classes.itemText}/>
            </ListItem>
          </Link>
          <Link to="/MaritalStatus">
            <ListItem button className={classes.itemLink}>
              <ListItemIcon className={classes.itemIcon}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="test 02" className={classes.itemText}/>
            </ListItem>
            </Link>
          </List>
        </Collapse>
        <ListItem button className={classes.itemLink}>
          <ListItemIcon className={classes.itemIcon}>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" className={classes.itemText}/>
        </ListItem>
        <ListItem button className={classes.itemLink}>
          <ListItemIcon className={classes.itemIcon}>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Integrations" className={classes.itemText}/>
        </ListItem>
      </>
    );*/
  }
}
const mapStateToProps = state => {
  return { authUser: state.authUser };
}

export default connect(mapStateToProps, { changeTitle })(withStyles(styles)(MyMenu));