import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from '../../actions/loginAction';
import { EditTextField } from '../../components/Share/EditComponents';
import i18next from '../../language/i18n';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mojosoft.dev"> */}
      MojoSoft
      {/* </Link>*/}{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#42A5F5', // Svijetlo plava boja za naglašavanje
    },
    background: {
      default: '#212121',
      paper: '#121212',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 600, // Podebljan naslov
    },
  },
});

class LoginPage extends React.Component {
  onSubmit = (formValues) => {
    this.props.login(formValues);
  };
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container className="fade-in" component="main" sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#212121',
          flexDirection: 'column',
        }}>
          <CssBaseline />
          {/* Logo i Naziv Aplikacije */}
          
          {/* <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', marginBottom: 4 }}>
        „Brzi krediti za vaš poslovni rast i razvoj“
      </Typography> */}

          <Box sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#121212',
            padding: '40px 30px', // Povećaj padding za "prozračniji" izgled
            borderRadius: '8px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)', // Dublji shadow za bolji 3D efekt
            width: '100%',
            maxWidth: '400px', // Maksimalna širina
          }}> 
          <Box sx={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            marginBottom:3
          }}>
            <Typography variant="h4" sx={{ color: '#42A5F5', fontWeight: 'bold' }}>
              MKD
            </Typography>
            <Box
              component="img"
              src="logo.svg"
              alt="Logo"
              sx={{
                width: '80px', // Širina logotipa
                height: '80px', // Visina logotipa
                marginLeft: 1, // Razmak između teksta i slike
                marginRight: 1, // Razmak između slike i drugog teksta
              }}
            />
            <Typography variant="h4" sx={{ color: '#42A5F5', fontWeight: 'bold' }}>
              Mojo
            </Typography>
          </Box>
            <Box component="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate sx={{ mt: 3, marginTop:0 }}>
              <Field
                name="email"
                component={this.props.EditTextField}
                label="Email"
                sx={{ marginBottom: 2 }}
              />
              <Field
                name="password"
                component={this.props.EditTextField}
                label="Password"
                type="password"
                sx={{ marginBottom: 2 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#42A5F5', // Osvježena boja gumba
                  '&:hover': {
                    backgroundColor: '#1E88E5', // Tamnija nijansa na hover
                  },
                }}
              >
                {i18next.t('SignIn')}
              </Button>

              {/* Linkovi za zaboravljenu lozinku i registraciju */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                {/* <Link href="#" variant="body2" sx={{ color: 'white', fontSize: '12px' }}>
              {i18next.t('ForgotPassword')}
            </Link> */}
                {/* <Link href="#" variant="body2" sx={{ color: 'white', fontSize: '12px' }}>
              {i18next.t('Register')}
            </Link> */}
              </Box>

            </Box>

            {/* Obaveštenje o sigurnosti */}
            {/* <Typography variant="body2" sx={{ marginTop: 2, color: 'gray', fontSize: '12px' }}>
          {i18next.t('SecureLoginMessage')}
        </Typography> */}

          </Box>

          {/* Copyright */}
          <Copyright sx={{ marginTop: 4 }} />
        </Container>
      </ThemeProvider>
    )
  }
}

const formWrapper = reduxForm({
  form: 'formLogin',
  destroyOnUnmount: false,
  enableReinitialize: true
})(LoginPage);

export default connect(null, { login, EditTextField })(formWrapper);
