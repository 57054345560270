import * as React from 'react';
import { styled, createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import { connect } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MyMenu from './MyMenu';
import { Route } from 'react-router-dom';
import CustomProgressBar from '../components/LayoutComponents/CustomProgressBar';

import BankPage from '../pages/Bank/BankPage';
import BranchPage from '../pages/Branch/BranchPage';
import CashBalancePage from '../pages/CashBalance/CashBalancePage';
import CashierPage from '../pages/Cashier/CashierPage';
import KUFPage from '../pages/KUF/KUFPage';
import CompanyPage from '../pages/Company/CompanyPage';
import CityPage from '../pages/City/CityPage';
import RegionPage from '../pages/Region/RegionPage';
import PartnerPage from '../pages/Partner/PartnerPage';
import PartnerDetailsPage from '../pages/Partner/PartnerDetailsPage';
import CreateLoanPage from '../pages/Loan/CreateLoanPage';
import LoanPage from '../pages/Loan/LoanPage';
import DraftPage from '../pages/Loan/DraftPage';
import LoanForApprovalPage from '../pages/Loan/LoanForApprovalPage';
import ApprovedLoanPage from '../pages/Loan/ApprovedLoanPage';
import LoanForRealizationPage from '../pages/Loan/LoanForRealizationPage';
import AllLoansPage from '../pages/Loan/AllLoansPage';
import LoanHalcomPage from '../pages/Loan/LoanHalcomPage';
import CompanyDetailsPage from '../pages/CompanyDetails/CompanyDetailsPage';
import OpinionOfTheReferentPage from '../pages/OpinionOfTheReferent/OpinionOfTheReferentPage';
import LoanMaintenancePage from '../pages/LoanMaintenance/LoanMaintenancePage';
import BankImportPage from '../pages/BankImport/BankImportPage';
import OrderPage from '../pages/Order/OrderPage';
import OrderTypePage from '../pages/OrderType/OrderTypePage';
import UserPage from '../pages/User/UserPage';
import PenaltyInterestSettingsPage from '../pages/PenaltyInterest/PenaltyInterestSettingsPage';
import PayWarningPage from '../pages/Warning/PayWarningPage';
import SMSWarningPage from '../pages/Warning/SMSWarningPage';
import DebtCollectionCostDetailsPage from '../pages/Warning/DebtCollectionCostDetailsPage';
import GeneralLedgerPage from '../pages/Report/GeneralLedgerPage';
import MatureAnnuitiesPage from '../pages/Report/MatureAnnuitiesPage';
import FinancialCardPage from '../pages/Report/FinancialCardPage';
import PortfolioPage from '../pages/Report/PortfolioPage';
import AgencyReportsPage from '../pages/Report/AgencyReportsPage';
import BrokerPage from '../pages/Broker/BrokerPage';
import ChangePasswordPage from '../pages/Login/ChangePasswordPage';
import PostingAccountPage from '../pages/PostingAccount/PostingAccountPage';
import OrderInImbalancePage from '../pages/PostingAccount/OrderInImbalancePage';
import RelatedPersonPage from '../pages/RelatedPerson/RelatedPersonPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import FileUploadTypePage from '../pages/File/FileUploadTypePage';
import Search from './header/Search';
import Notification from './header/Notification';
import UserManu from './header/UserManu';

import LegalService_ExecutionTypePage from '../pages/LegalService/LegalService_ExecutionTypePage';
import LegalService_AllDebtsPage from '../pages/LegalService/LegalService_AllDebtsPage';
import LegalService_CourtPage from '../pages/LegalService/LegalService_CourtPage';
import LegalService_DonePage from '../pages/LegalService/LegalService_DonePage';
import LegalService_ExecutionPage from '../pages/LegalService/LegalService_ExecutionPage';
import LegalService_IskupPage from '../pages/LegalService/LegalService_IskupPage';
import LegalService_WarningPage from '../pages/LegalService/LegalService_WarningPage';

import LoanApprovalPage from '../pages/LoanApproval/LoanApprovalPage';
import LoanApprovalSettingsPage from '../pages/LoanApprovalSettings/LoanApprovalSettingsPage';
import BoardMemberPage from '../pages/BoardMember/BoardMemberPage';
import EarlyRepaymentSettingsPage from '../pages/EarlyRepaymentSettings/EarlyRepaymentSettingsPage'
import LoanTypePage from '../pages/LoanType/LoanTypePage';
import LifeInsurancePage from '../pages/LifeInsurance/LifeInsurancePage';
import YearLockPage from '../pages/YearLock/YearLockPage';
import CRKArchivePage from '../pages/CRKArchive/CRKArchivePage';
import SourceOfFinancingPage from '../pages/SourceOfFinancing/SourceOfFinancingPage';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

//import Chart from './Chart';
//import Deposits from './Deposits';
//import Orders from './Orders';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



class DashboardContent extends React.Component {
  constructor(props) {
    super(props);
    let themeMode = localStorage.getItem('themeMode');
    this.state = {
      isOpen: true,
      isDark: themeMode === null || themeMode === 'dark'
    }
  }
  lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
  darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#42A5F5', // Svijetlo plava boja za naglašavanje
      },
    },
  });
  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  getRenderButton = () => {
    if (this.state.isDark) {
      return (<LightModeIcon />);
    }
    else {
      return (<DarkModeIcon />);
    }
  }

  admin = 'Admin';
  clientAdmin = 'ClientAdmin';
  companyAdmin = 'CompanyAdmin';
  broker = 'Broker';
  seller = 'Seller';
  legalService = 'LegalService';
  accounting = 'Accounting';

  render() {
    return (
      <ThemeProvider theme={this.state.isDark ? this.darkTheme : this.lightTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={this.state.isOpen}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={this.toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(this.state.isOpen && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {this.props.title}
              </Typography>
              <Search></Search>
              <IconButton color="inherit" onClick={() => {
                let newValue = !this.state.isDark
                this.setState({ isDark: newValue });
                localStorage.setItem('themeMode', newValue ? 'dark' : 'light');
              }}>
                {this.getRenderButton()}
              </IconButton>
              <Notification />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                paddingRight="10px"
                paddingLeft="20px"
              >
                {this.props.userName}
              </Typography>
              <UserManu />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={this.state.isOpen}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={this.toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <MyMenu isDark={this.state.isDark} isOpen={this.state.isOpen} />
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="1920" sx={{ mt: 4, mb: 4 }}> <CustomProgressBar></CustomProgressBar>

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1 || this.props.authUser?.roles.indexOf(this.legalService) > -1?
                (
                  <>
                    <Route path="/Company" component={CompanyPage}></Route>
                    <Route path="/Bank" component={BankPage}></Route>
                    <Route path="/City" component={CityPage}></Route>
                    <Route path="/OpinionOfTheReferent" component={OpinionOfTheReferentPage}></Route>
                    <Route path="/LoanType" component={LoanTypePage}></Route>

                    <Route path="/LegalService_AllDebts" component={LegalService_AllDebtsPage}></Route>
                    <Route path="/LegalService_Warning" component={LegalService_WarningPage}></Route>
                    <Route path="/LegalService_Redemption" component={LegalService_IskupPage}></Route>
                    <Route path="/LegalService_Execution" component={LegalService_ExecutionPage}></Route>
                    <Route path="/LegalService_Done" component={LegalService_DonePage}></Route>
                    <Route path="/LegalService_ExecutionType" component={LegalService_ExecutionTypePage}></Route>
                    <Route path="/LegalService_Court" component={LegalService_CourtPage}></Route>
                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1 ?
                (
                  <>
                    <Route path="/PenaltyInterestSettings" component={PenaltyInterestSettingsPage}></Route>
                    <Route path="/BoardMember" component={BoardMemberPage}></Route>
                    <Route path="/CompanyDetails" component={CompanyDetailsPage}></Route>
                    <Route path="/Branch" component={BranchPage}></Route>
                    <Route path="/Cashier" component={CashierPage}></Route>
                    <Route path="/Region" component={RegionPage}></Route>
                    <Route path="/LoanApprovalSettings" component={LoanApprovalSettingsPage}></Route>
                    <Route path="/EarlyRepaymentSettings" component={EarlyRepaymentSettingsPage} ></Route>
                    <Route path="/FileUploadType" component={FileUploadTypePage}></Route>
                    <Route path="/LoanApproval" component={LoanApprovalPage}></Route>
                    <Route path="/LifeInsurance" component={LifeInsurancePage}></Route>
                    <Route path="/YearLock" component={YearLockPage}></Route>
                    <Route path="/SourceOfFinancing" component={SourceOfFinancingPage}></Route>
                    
                    
                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1
                || this.props.authUser?.roles.indexOf(this.accounting) > -1 ?
                (
                  <>
                    <Route path="/CashBalance" component={CashBalancePage}></Route>
                    <Route path="/KUF" component={KUFPage}></Route>
                    
                    <Route path="/PostingAccount" component={PostingAccountPage} exact></Route>
                    <Route path="/PostingAccount/:year/:number" component={PostingAccountPage} exact></Route>
                    <Route path="/OrderInImbalance" component={OrderInImbalancePage}></Route>
                    <Route path="/BankImport" component={BankImportPage}></Route>
                    <Route path="/Order" component={OrderPage}></Route>
                    <Route path="/OrderType" component={OrderTypePage}></Route>
                    
                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1
                || this.props.authUser?.roles.indexOf(this.companyAdmin) > -1 ?
                (
                  <>
                    <Route path="/PayWarning" component={PayWarningPage}></Route>
                    <Route path="/SMSWarning" component={SMSWarningPage}></Route>
                    <Route path="/Broker" component={BrokerPage}></Route>
                    <Route path="/User" component={UserPage}></Route>
                    <Route path="/RelatedPerson" component={RelatedPersonPage}></Route>
                    <Route path="/DebtCollectionCostDetails" component={DebtCollectionCostDetailsPage}></Route>
                    <Route path="/FinancialCard" component={FinancialCardPage}></Route>
                    <Route path="/AgencyReports" component={AgencyReportsPage}></Route>  
                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1
                || this.props.authUser?.roles.indexOf(this.accounting) > -1 || this.props.authUser?.roles.indexOf(this.companyAdmin) > -1 ?
                (
                  <>
                    <Route path="/LoanMaintenance" component={LoanMaintenancePage}></Route>
                    <Route path="/CRKArchive" component={CRKArchivePage}></Route>                    
                    <Route path="/LoanHalcom" component={LoanHalcomPage}></Route>
                    <Route path="/GeneralLedger" component={GeneralLedgerPage}></Route>

                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1
                || this.props.authUser?.roles.indexOf(this.companyAdmin) > -1 || this.props.authUser?.roles.indexOf(this.broker) > -1
                || this.props.authUser?.roles.indexOf(this.seller) > -1 ?
                (
                  <>
                    <Route path="/Loan" component={CreateLoanPage} exact></Route>
                    <Route path="/Loan/:id" component={LoanPage} exact></Route>
                    <Route path="/Draft" component={DraftPage}></Route>
                    <Route path="/CreditBoard" component={LoanForApprovalPage}></Route>
                    <Route path="/ApprovedLoan" component={ApprovedLoanPage}></Route>
                    <Route path="/AllLoans" component={AllLoansPage}></Route>
                  </>
                ) : null}

              {this.props.authUser?.roles.indexOf(this.admin) > -1 || this.props.authUser?.roles.indexOf(this.clientAdmin) > -1
                || this.props.authUser?.roles.indexOf(this.companyAdmin) > -1 || this.props.authUser?.roles.indexOf(this.accounting) > -1
                || this.props.authUser?.roles.indexOf(this.broker) > -1 || this.props.authUser?.roles.indexOf(this.seller) > -1 ?
                (
                  <>
                    <Route path="/LoanForRealization" component={LoanForRealizationPage}></Route>
                    <Route path="/Portfolio" component={PortfolioPage}></Route>  
                    <Route path="/MatureAnnuities" component={MatureAnnuitiesPage}></Route>                
                  </>
                ) : null}

              <Route path="/" exact component={DashboardPage}></Route>
              <Route path="/ChangePassword" component={ChangePasswordPage}></Route>
              <Route path="/Partner" component={PartnerPage} exact></Route>
              <Route path="/PartnerDetails" component={PartnerDetailsPage} exact></Route>
              <Route path="/PartnerDetails/:id" component={PartnerDetailsPage} exact></Route>





            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { authUser: state.authUser,
    userName: state.authUser?.userName
   };
}

export default connect(mapStateToProps, {})(DashboardContent);


