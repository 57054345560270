import api from "../api/myApi";
import { showPDFFile, showTXTFile, showExcelFile } from "../share/fileHandler";

export const getCreditRequest = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/CreditRequest/'+ id, {responseType: 'blob'}));
}
export const getCreditworthinessAnalysis = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/CreditworthinessAnalysis/'+ id, {responseType: 'blob'}));
}
export const getContract = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/Contract/'+ id, {responseType: 'blob'}));
}
export const getPledgeAgreement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/PledgeAgreement/'+ id, {responseType: 'blob'}));
}
export const getBillStatement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/BillStatement/'+ id, {responseType: 'blob'}));
}

export const getBillStatementHandover = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/BillStatementHandover/'+ id, {responseType: 'blob'}));
}

// export const getCoDebtorBillStatement = (id) => async (dispatch) => {
//     showPDFFile(await api.get('/api/v1/Report/CoDebtorBillStatement/'+ id, {responseType: 'blob'}));
// }
export const getPaymentOrders = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/PaymentOrders/'+ id, {responseType: 'blob'}));
}
export const getAdministrativeBlock = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/AdministrativeBlock/'+ id, {responseType: 'blob'}));
}
// export const getAdministrativeBlockGarantor = (id) => async (dispatch) => {
//     showPDFFile(await api.get('/api/v1/Report/AdministrativeBlockGarantor/'+ id, {responseType: 'blob'}));
// }
export const getLoanPlacementStatement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/LoanPlacementStatement/'+ id, {responseType: 'blob'}));
}
export const getApprovalDecision = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/ApprovalDecision/'+ id, {responseType: 'blob'}));
}
export const getCertificateOfObligations = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/CertificateOfObligations/'+ id, {responseType: 'blob'}));
}
export const getDebtRepaymentPlan = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/DebtRepaymentPlan/'+ id, {responseType: 'blob'}));
}
export const getDebtBalance = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/DebtBalance/'+ id, {responseType: 'blob'}));
}
export const getDebtBalance2 = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/DebtBalance2/', data, {responseType: 'blob'}));
}
export const getGuaranteeBillStatement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/GuaranteeBillStatement/'+ id, {responseType: 'blob'}));
}
export const getGuaranteeAgreement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/GuaranteeAgreement/'+ id, {responseType: 'blob'}));
}
export const getSolidarGuaranteeAgreement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/SolidarGuaranteeAgreement/'+ id, {responseType: 'blob'}));
}
export const getSolidarCoDebtorAgreement = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/SolidarCoDebtorAgreement/'+ id, {responseType: 'blob'}));
}
export const getArchiveFile = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/ArchiveFile/'+ id, {responseType: 'blob'}));
}
export const getI02OB = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/I02OB/'+ id, {responseType: 'blob'}));
}
export const getCRK = (crkRequestID) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/CRK/'+ crkRequestID, {responseType: 'blob'}));
}
export const getConsent = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/GetConsent/'+ id, {responseType: 'blob'}));
}
export const getDebt = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/GetDebt/'+ id, {responseType: 'blob'}));
}
//Partner
export const getDetailsByAccount = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/DetailsByAccount', data, {responseType: 'blob'}));
}
export const getDetailsByAccountWithoutGrouping = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/DetailsByAccountWithoutGrouping', data, {responseType: 'blob'}));
}
export const getDetailsByConnections = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/DetailsByConnections', data, {responseType: 'blob'}));
}
export const getSumByAccount = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/SumByAccount', data, {responseType: 'blob'}));
}
export const getIOS = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/IOS', data, {responseType: 'blob'}));
}
export const getIOSNeto = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/IOSNeto', data, {responseType: 'blob'}));
}
export const getReview = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/Review', data, {responseType: 'blob'}));
}
export const getPartnerLoans = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/PartnerLoans', data, {responseType: 'blob'}));
}
export const getPartnerAnalysisForTheCreditBoard = (data) => async (dispatch) => { 
    showPDFFile(await api.post('/api/v1/Report/PartnerAnalysisForTheCreditBoard', data, {responseType: 'blob'}));
}
export const getDebtStatus = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/DebtStatus', data, {responseType: 'blob'}));
}
export const getListOfPayments = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/ListOfPayments/' + id, {responseType: 'blob'}));
}
export const geWarningsBeforeTerminationOfContract = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/WarningsBeforeTerminationOfContract/' + id, {responseType: 'blob'}));
}
export const getTerminationOfContract = (id) => async (dispatch) => {
    showPDFFile(await api.get('/api/v1/Report/TerminationOfContract/' + id, {responseType: 'blob'}));
}

//Glavna knjiga
export const getGrossBalance = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GrossBalance', data, {responseType: 'blob'}));
}
export const getExtendedReview = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/ExtendedReview', data, {responseType: 'blob'}));
}
export const getClasses = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/Classes', data, {responseType: 'blob'}));
}
export const getGeneralLedger = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GeneralLedger', data, {responseType: 'blob'}));
}
export const getOpenItemsByPartnerAndAccount = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/OpenItemsByPartnerAndAccount', data, {responseType: 'blob'}));
}
export const getCashFlow = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/CashFlow', data, {responseType: 'blob'}));
}
//MatureAnnuities
export const getLoanPerBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanPerBranch', data, {responseType: 'blob'}));
}
export const getLoanPerCities = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanPerCities', data, {responseType: 'blob'}));
}
export const getSumByBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/SumByBranch', data, {responseType: 'blob'}));
}
export const getLoanTerms = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanTerms', data, {responseType: 'blob'}));
}
export const getWeeklyFlows = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/WeeklyFlows', data, {responseType: 'blob'}));
}
export const getMonthlyCashFlows = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/MonthlyCashFlows', data, {responseType: 'blob'}));
}
export const getMethodOfKnowledge = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/MethodOfKnowledge', data, {responseType: 'blob'}));
}
export const getPaymentDelay = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/PaymentDelay', data, {responseType: 'blob'}));
}
export const getLoanProducts = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanProducts', data, {responseType: 'blob'}));
}
export const getLoanProductsTerms = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanProductsTerms', data, {responseType: 'blob'}));
}
export const getLoanTermsFull = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanTermsFull', data, {responseType: 'blob'}));
}
export const getLoanPerPerson = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanPerPerson', data, {responseType: 'blob'}));
}
export const getPaymentDelayDetails = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/PaymentDelayDetails', data, {responseType: 'blob'}));
}
export const getCategories = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/Categories', data, {responseType: 'blob'}));
}
export const getCategoriesPerBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/CategoriesPerBranch', data, {responseType: 'blob'}));
}
export const getPaymentDelayPerBroker = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/PaymentDelayPerBroker', data, {responseType: 'blob'}));
}
export const getPaymentDelayPerBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/PaymentDelayPerBranch', data, {responseType: 'blob'}));
}
export const getLifeInsurance = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LifeInsurance', data, {responseType: 'blob'}));
}
export const getCalled = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/Called', data, {responseType: 'blob'}));
}
export const getCallToday = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/CallToday', data, {responseType: 'blob'}));
}
export const getABRSInterestRates = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/ABRSInterestRates', data, {responseType: 'blob'}));
}
export const getLoanProductsTermsFBiHSum = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/LoanProductsTermsFBiHSum', data, {responseType: 'blob'}));
}
export const getGetBrokerPerLoanType = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetBrokerPerLoanType', data, {responseType: 'blob'}));
}
export const getFinancialCard = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetFinancialCard', data, {responseType: 'blob'}));
}
export const getApprovedLoansByEmployee = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetApprovedLoansByEmployee', data, {responseType: 'blob'}));
}
export const getApprovedLoansByBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetApprovedLoansByBranch', data, {responseType: 'blob'}));
}
export const getApprovedLoansByInterestRate = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetApprovedLoansByInterestRate', data, {responseType: 'blob'}));
}
export const getApprovedLoansByLoanType = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetApprovedLoansByLoanType', data, {responseType: 'blob'}));
}
export const getApprovedLoansByCycle = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetApprovedLoansByCycle', data, {responseType: 'blob'}));
}
export const getPerfByLoanOfficers = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPerfByLoanOfficers', data, {responseType: 'blob'}));
}

//Halcom
export const getPaymentOrderImportExportFile = (data) => async (dispatch) => {
    showTXTFile(await api.post('/api/v1/Report/GetPaymentOrderImportExportFile', data, {responseType: 'blob'}));
}

//Portfolio
export const getPortfolio = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolio', data, {responseType: 'blob'}));
}

export const getPortfolioByPartnerType = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioByPartnerType', data, {responseType: 'blob'}));
}

export const getPortfolioByGender = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioByGender', data, {responseType: 'blob'}));
}

export const getPortfolioByBranch = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioByBranch', data, {responseType: 'blob'}));
}

export const getPortfolioByLoanType = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioByLoanType', data, {responseType: 'blob'}));
}

export const getPortfolioByInterestRate = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioByInterestRate', data, {responseType: 'blob'}));
}


export const getPortfolioAtRiskShortened = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioAtRiskShortened', data, {responseType: 'blob'}));
}
export const getPortfolioAtRisk = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPortfolioAtRisk', data, {responseType: 'blob'}));
}
export const getAnalyticalPortfolio = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetAnalyticalPortfolio', data, {responseType: 'blob'}));
}

export const getOverdueLoans = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetOverdueLoans', data, {responseType: 'blob'}));
}

export const getOverdueLoansAnalytics = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetOverdueLoansAnalytics', data, {responseType: 'blob'}));
}

export const getProvFCovCreLos = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetProvFCovCreLos', data, {responseType: 'blob'}));
}

export const getOutgoingLoans = (data) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/OutgoingLoans', data, {responseType: 'blob'}));
}

//AgencyReports
export const getDisbursedLoans = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetDisbursedLoans', data, {responseType: 'blob'}));
}
export const getPortfolioFBA = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetPortfolioFBA', data, {responseType: 'blob'}));
}
export const getRezerveZaABRS = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetRezerveZaABRS', data, {responseType: 'blob'}));
}
export const getRocnaISektorskaStruktura = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetRocnaISektorskaStruktura', data, {responseType: 'blob'}));
}
export const getTacka07 = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetTacka07', data, {responseType: 'blob'}));
}
export const getTacka08 = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetTacka08', data, {responseType: 'blob'}));
}
export const getTacka11 = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetTacka11', data, {responseType: 'blob'}));
}
export const getTacka12 = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetTacka12', data, {responseType: 'blob'}));
}
export const getTacka13 = (data) => async (dispatch) => {
    showExcelFile(await api.post('/api/v1/Report/GetTacka13', data, {responseType: 'blob'}));
}


//nerasporedjeno

export const getPersonalizedInformationSheet = (id) => async (dispatch) => {
    showPDFFile(await api.post('/api/v1/Report/GetPersonalizedInformationSheet', {ID: id}, {responseType: 'blob'}));
}
        //#CODE#
