import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCompanyDetails } from '../../actions/companyDetailsAction';
import { EditSelectField, EditTextField, EditIntegerNumberField, EditCheckbox, EditDecimalNumberField } from '../Share/EditComponents';

class FormCompanyDetails extends React.Component {
    handleClose = () => {
        this.props.closeDialogCompanyDetails();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>

                            <div>
                                <Field
                                    name='companyID'
                                    component={this.props.EditSelectField}
                                    label='Company'
                                    dataSource={this.props.companiesSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            <div>
                                <Field
                                    name='cityID'
                                    component={this.props.EditSelectField}
                                    label='City'
                                    dataSource={this.props.citiesSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='address'
                                    component={this.props.EditTextField}
                                    label='Address'
                                />
                            </div>
                            <div>
                                <Field
                                    name='phone'
                                    component={this.props.EditTextField}
                                    label='Phone'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fax'
                                    component={this.props.EditTextField}
                                    label='Fax'
                                />
                            </div>
                            <div>
                                <Field
                                    name='bankAccount'
                                    component={this.props.EditTextField}
                                    label='BankAccount'
                                />
                            </div><div>
                                <Field
                                    name='bankName'
                                    component={this.props.EditTextField}
                                    label='BankName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='foreignCurrencyAccount'
                                    component={this.props.EditTextField}
                                    label='ForeignCurrencyAccount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='entityID'
                                    component={this.props.EditSelectField}
                                    label='Entity'
                                    dataSource={this.props.entitiesSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='regionID'
                                    component={this.props.EditSelectField}
                                    label='Region'
                                    dataSource={this.props.regionsSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='jIB'
                                    component={this.props.EditTextField}
                                    label='JIB'
                                />
                            </div>
                            <div>
                                <Field
                                    name='isMain'
                                    component={this.props.EditCheckbox}
                                    label='IsMain'
                                />
                            </div>
                            <div>
                                <Field
                                    name='warningsArePaid'
                                    component={this.props.EditCheckbox}
                                    label='WarningsArePaid'
                                />
                            </div>
                            <div>
                                <Field
                                    name='maxAutoWriteOffAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='MaxAutoWriteOffAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='registeredWith'
                                    component={this.props.EditTextField}
                                    label='RegisteredWith'
                                />
                            </div>
                            <div>
                                <Field
                                    name='courtDecision'
                                    component={this.props.EditTextField}
                                    label='CourtDecision'
                                />
                            </div>
                            <div>
                                <Field
                                    name='registrationNumber'
                                    component={this.props.EditTextField}
                                    label='RegistrationNumberM'
                                />
                            </div>
                            <div>
                                <Field
                                    name='initialCapital'
                                    component={this.props.EditDecimalNumberField}
                                    label='InitialCapital'
                                />
                            </div>
                            <div>
                                <Field
                                    name='companyActivityCodeID'
                                    component={this.props.EditSelectField}
                                    label='ActivityCode'
                                    dataSource={this.props.companyActivityCodesSelectBox}
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.companyID) {
        errors.companyID = 'Polje je obavezno!';
    }
    if (!formValues.name) {
        errors.name = 'Polje je obavezno!';
    }
    if (!formValues.cityID) {
        errors.cityID = 'Polje je obavezno!';
    }
    if (!formValues.address) {
        errors.address = 'Polje je obavezno!';
    }
    if (!formValues.phone) {
        errors.phone = 'Polje je obavezno!';
    }
    if (!formValues.fax) {
        errors.fax = 'Polje je obavezno!';
    }
    if (!formValues.bankAccount) {
        errors.bankAccount = 'Polje je obavezno!';
    }
    if (!formValues.bankName) {
        errors.bankName = 'Polje je obavezno!';
    }
    if (!formValues.foreignCurrencyAccount) {
        errors.foreignCurrencyAccount = 'Polje je obavezno!';
    }
    if (!formValues.entityID) {
        errors.entityID = 'Polje je obavezno!';
    }
    if (!formValues.jIB) {
        errors.jIB = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        companiesSelectBox: Object.values(state.companiesSelectBox), 
        citiesSelectBox: Object.values(state.citiesSelectBox), 
        entitiesSelectBox: Object.values(state.entitiesSelectBox),
         regionsSelectBox: Object.values(state.regionsSelectBox), 
         companyActivityCodesSelectBox: Object.values(state.companyActivityCodesSelectBox)
        }
}
const formWrapper = reduxForm({
    form: 'formCompanyDetails',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormCompanyDetails);

export default connect(mapStateToProps, { closeDialogCompanyDetails, EditSelectField, EditTextField, EditIntegerNumberField, EditCheckbox, EditDecimalNumberField })(formWrapper);